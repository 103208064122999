import React from 'react';
import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const NotFoundPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <main>
      <title>Not found</title>
      {t('notFoundPage')}
    </main>
  );
};

export default NotFoundPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
